<template lang="">
<div style="height:100%;padding:0 20px 10px;box-sizing:border-box">
    <div class="selectionBar">
        <div class="flex flex-ai-c flex-wrap">
            <label>场景：</label>
            <!-- <el-select v-model="select.is_stop" placeholder="状态" clearable style="width:140px" @change="curr=1;getList()">
                <el-option label="启用" value="1"></el-option>
                <el-option label="禁用" value="2"></el-option>
            </el-select> -->
            <el-input placeholder="车牌号" v-model.trim="select.car_no" :clearable="true" size="small" class="input-with-select" prefix-icon="el-icon-search" style="width:150px;margin-right:auto;" @input="curr=1;getList()"></el-input>
            <el-button type="primary" block size="small" style="margin-left:auto" @click="getAll">全部</el-button>
        </div>
    </div>
    <!-- 表格 -->
    <el-row :gutter="20" style="height:calc( 100% - 210px )">
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" style="height:100%">
            <el-card shadow="hover" style="margin-top: 10px;">
                <el-calendar v-model="value">
                    <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
                    <template slot="dateCell" slot-scope="{date, data}">
                        <div class="" style="height:100%" @click="getList(data.day)">
                            <p>
                                {{ data.day.split('-').slice(1).join('-') }}
                            </p>
                            <div v-for="(item,index) in byDayList" :key="index" style="textAlign:center;lineHeight:2">
                                <p v-if="item.time==data.day" style="color:#F56C6C">{{item.number}}个</p>
                            </div>
                        </div>
                    </template>
                </el-calendar>
            </el-card>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" style="height:100%">
            <div class="table" style="height:100%">
                <el-table ref="table" :data="list" highlight-current-row :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" @sort-change='getList()' height="calc( 100% - 41px )">
                    <el-table-column prop="car.car_no" label="车牌号" align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="car.realname" label="车主姓名" align="center" show-overflow-tooltip></el-table-column>
                    <!-- <el-table-column prop="product.name" label="产品名称" align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="product_cost" label="产品用量" align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column label="产品图片" align="center" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-image v-if="scope.row.product_img_arr&&scope.row.product_img_arr.length>0" style="width: 50px; height: 50px" :src="getImgUrl(scope.row.product_img_arr[0])" :preview-src-list="getImgUrl(scope.row.product_img_arr,'arr')" fit="cover"></el-image>
                            <p v-else>暂无</p>
                        </template>
                    </el-table-column> -->
                    <!-- <el-table-column prop="remind_time" label="提醒时间" align="center" show-overflow-tooltip></el-table-column> -->
                    <el-table-column prop="next_time" label="下次保养时间" align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="note" label="备注" align="center" show-overflow-tooltip></el-table-column>
                    <!-- <el-table-column prop="is_stop" label="开关" align="center" show-overflow-tooltip>
                    <template slot-scope="scope">
                    <el-switch v-model="scope.row.is_stop" :active-value="1" :inactive-value="2" @change="setSwitch( scope.row.id , scope.row.is_stop )"></el-switch>
                </template>
            </el-table-column> -->
                    <!-- <el-table-column label="操作" align="center" >
            <template slot-scope="scope">
            <el-button plain type="success" size="small" icon="el-icon-edit-outline" @click="edit(scope.row)"></el-button>
            <el-button plain type="danger" size="small" icon="el-icon-delete" @click="handleDel(scope.row.id)"></el-button>
        </template>
    </el-table-column> -->
                </el-table>
                <!-- 分页 -->
                <div class="paging">
                    <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
                </div>
            </div>
        </el-col>
    </el-row>
</div>
</template>

<script>
export default {
    data: function() {
        return {
            searchValue: '', //导入input值
            select: {},
            list: [],
            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
            byDayList: [],

            value: new Date(),
        }
    },
    watch: {
        value(newval) {
            this.value = newval;
        }
    },
    mounted: function() {
        this.getList();
        this.countByDay(this.$common.timeFormatter(this.value, 'month'))

        // 点击上个月
        let prevBtn1 = document.querySelector('.el-calendar__button-group .el-button-group>button:nth-child(1)');
        prevBtn1.addEventListener('click', () => {
            this.countByDay(this.$common.timeFormatter(this.value, 'month'));
            this.getList(this.$common.timeFormatter(this.value, 'month'));
        })
        // 点击今天
        let prevBtn2 = document.querySelector('.el-calendar__button-group .el-button-group>button:nth-child(2)');
        prevBtn2.addEventListener('click', () => {
            this.countByDay(this.$common.timeFormatter(this.value, 'month'));
            this.getList(this.$common.timeFormatter(this.value));
        })
        // 点击下个月
        let prevBtn3 = document.querySelector('.el-calendar__button-group .el-button-group>button:nth-child(3)');
        prevBtn3.addEventListener('click', () => {
            this.countByDay(this.$common.timeFormatter(this.value, 'month'));
            this.getList(this.$common.timeFormatter(this.value, 'month'));
        })
    },
    methods: {
        getAll() {
            this.value=new Date();
            this.countByDay(this.$common.timeFormatter(this.value, 'month'));
            this.getList();
        },
        countByDay(month) {
            this.http.post('/admin.carCare/countByDay', {
                month
            }).then(re => {
                this.byDayList = re.data
            })
        },
        getList: function(time) {
            this.http.post('/admin.carCare/index', {
                curr: this.curr,
                row: this.row,
                search: this.searchValue,
                remind_time: 0,
                time: time ? time : '',
                car_no:this.select.car_no
            }).then(re => {
                this.list = re.data.list;
                this.curr = re.data.curr;
                this.pages = re.data.pages;
                this.count = re.data.count;
            })
        },
        // 图片转换
        getImgUrl(item, type) {
            var url;
            var urlArr=[]
            if (type == 'arr') {
                item.forEach((i) => {
                    urlArr.push(this.domain + i);
                })
                return urlArr;
            } else {
                url = this.domain + item;
                return url;
            }
        },
        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
    }
}
</script>
<style media="screen">
.is-selected {
    color: #1989FA;
}
</style>
